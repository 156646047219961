import React, { useState } from "react";
import { ReactMic } from "@cleandersonlobo/react-mic";
import {
	Box,
	Button,
	Container,
	Grid,
	Typography,
	TextField,
} from "@material-ui/core";
import { PlayArrow, Stop } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import AWS from "aws-sdk";
import logo from "./tio_logo.png";
import {
	Dialog,
	CircularProgress,
	DialogContent,
	makeStyles,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
	dialogContent: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		minHeight: "10vh",
		padding: theme.spacing(2),
	},
	progress: {
		color: "#3C1053",
		marginRight: theme.spacing(2),
	},
	text: {
		maxWidth: "60%",
	},
}));

function App() {
	const classes = useStyles();
	const [record, setRecord] = useState(false);
	const [audioUrl, setAudioUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [textContent, setTextContent] = useState(
		"Detected insights will appear here.",
	);


	let downloadInterval;

	function handleTextChange(event) {
		setTextContent(event.target.value);
	}

	const startRecording = () => {
		setTextContent("Detected insights will appear here.");
		setRecord(true);
	};

	const stopRecording = () => {
		setRecord(false);
	};

	const downloadPrediction = async (fileName) => {
		const data = {
			filename: `${fileName}.json`,
		};

		try {
			const response = await axios.post(
				"https://fso4yzpvj9.execute-api.eu-west-2.amazonaws.com/test/detect-shifts-in-emotion",
				data,
				{
					headers: {
						"x-api-key": "X65TNete5n6iiADDR0Br3nREFkWg7RT20P1dOBsb",
						"Content-Type": "application/json",
					},
				},
			);

			const resJSONArray = response.data.body;
			console.log("Response: ", resJSONArray);

			
			if (typeof resJSONArray === 'string' && resJSONArray.trim() === "EMPTY_TRANSCRIPT") {
				setTextContent(
					`Empty transcript as no insights have been detected. Please try again.`
				);
				setLoading(false);
				clearInterval(downloadInterval);
			} else if (Array.isArray(resJSONArray) && resJSONArray.length > 0) {
				const resJSON = resJSONArray[0];
				console.log(resJSON, resJSON["text"], resJSON.text);
				if (resJSON && resJSON["text"]) {
					const prediction = resJSON.text;
					setTextContent(
						`Insights have been detected in the following phrases:${prediction}`
					);
				} else {
					setTextContent("Received invalid response format. Please try again.");
				}
				setLoading(false);
				clearInterval(downloadInterval);
			}
		} catch (err) {
			console.error(`Error downloading insights:`, err);
			setTextContent("An error occurred while processing. Please try again.");
			setLoading(false);
			clearInterval(downloadInterval);
		}
	};

	const onData = (recordedBlob) => {};

	const onStop = async (recordedBlob) => {
		uploadToS3(recordedBlob.blob);
	};
	const uploadToS3 = (blob) => {
		const s3 = new AWS.S3({
			accessKeyId: "AKIAXYKJRFK7SGUSLYVS",
			secretAccessKey: "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",
			region: "eu-west-2",
		});

		const fileName = uuidv4();
		const params = {
			Bucket: "tio-audios-live",
			Key: `${fileName}.mp3`,
			Body: blob,
			ContentType: "audio/mp3",
		};

		setLoading(true);

		s3.upload(params, (err, data) => {
			if (err) {
				console.error("Error uploading audio:", err);
			} else {
				setAudioUrl(data.Location);

				downloadInterval = setInterval(() => {
					downloadPrediction(fileName);
				}, 30 * 1000);
			}
		});
	};

	return (
		<Container style={{ height: "100vh" }}>
			<Dialog open={loading} maxWidth="md" fullWidth>
				<DialogContent className={classes.dialogContent}>
					<CircularProgress
						size={60}
						thickness={5}
						className={classes.progress}
					/>
					<Typography
						variant="subtitle1"
						color="textSecondary"
						className={classes.text}
					>
						We’re just getting your insights for you. They’ll be shown soon…
						Please don’t refresh this page whilst you wait.
					</Typography>
				</DialogContent>
			</Dialog>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item>
					<img src={logo} alt="Logo" style={{ maxHeight: "80px" }} />
				</Grid>
			</Grid>
			<Box>
				<Box mb={4} style={{ marginTop: "10px" }}>
					<Typography variant="h6" align="center">
						Hit ‘Start Recording’ and go for a walk whilst you talk to the app
						for up to 10 minutes. When you are done, press ‘Stop Recording’ and
						your insights will be displayed below.
					</Typography>
				</Box>
				<Grid
					container
					style={{ marginTop: "10px" }}
					spacing={4}
					justifyContent="center"
				>
					<Grid item>
						<Button
							variant="contained"
							style={{
								backgroundColor: "#E8385A",
								width: "200px",
								color: "#FFFFFF",
							}}
							onClick={startRecording}
							startIcon={<PlayArrow />}
							disabled={record}
						>
							Start Recording
						</Button>
					</Grid>
					<Grid item>
						<Button
							style={{
								backgroundColor: "#3C1053",
								width: "200px",
								color: "#FFFFFF",
							}}
							variant="contained"
							color="secondary"
							onClick={stopRecording}
							startIcon={<Stop />}
							disabled={!record}
						>
							Stop Recording
						</Button>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item>
						<ReactMic
							record={record}
							className="sound-wave"
							onData={onData}
							onStop={onStop}
							strokeColor="#000000"
							backgroundColor="#FFFFFF"
							mimeType="audio/mp3"
							width={300}
						/>
					</Grid>
				</Grid>
				{audioUrl && (
					<audio
						controls
						src={audioUrl}
						type="audio/mp3"
						style={{ width: "100%" }}
					/>
				)}
			</Box>
			{/* <Grid
        container
        style={{ marginTop: "10px" }}
        spacing={4}
        justifyContent="center"
      >
        <Grid item>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3C1053",
              width: "250px",
              color: "#FFFFFF",
            }}
            onClick={downloadPrediction}
            startIcon={<SearchRounded />}
          >
            Download Insights
          </Button>
        </Grid>
      </Grid> */}
			<div style={{ marginTop: "40px", marginBottom: "40px", width: "100%" }}>
				<TextField
					label=""
					multiline
					disabled
					variant="outlined"
					value={textContent}
					onChange={handleTextChange}
					fullWidth
				/>
			</div>

			<div>
				<Typography variant="subtitle2" align="center">
					Version 0.2 (UAT)
				</Typography>
			</div>

		</Container>
	);
}

export default App;
